var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('l-map', {
    directives: [{
      name: "resize",
      rawName: "v-resize",
      value: _vm.onResize,
      expression: "onResize"
    }],
    ref: _vm.mapRef,
    staticClass: "fill-height flex-grow-1",
    attrs: {
      "center": _vm.center,
      "zoom": _vm.computedZoom,
      "options": {
        zoomControl: false
      }
    }
  }, [_c('l-tile-layer', {
    attrs: {
      "url": _vm.url,
      "attribution": _vm.attribution
    }
  }), _c('l-control-scale', {
    attrs: {
      "position": "bottomright",
      "imperial": false,
      "metric": true
    }
  }), _c('l-control-zoom', {
    attrs: {
      "position": 'bottomright'
    }
  }), _c('l-control', {
    style: _vm.isMobile ? 'width: 100%' : 'max-width: 408px',
    attrs: {
      "position": 'topleft'
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "stretch",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "d-flex flex-column fill-height",
    attrs: {
      "color": _vm.background,
      "flat": "",
      "rounded": "0"
    },
    on: {
      "wheel": function wheel($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('v-card-actions', {
    staticClass: "d-flex flex-column align-stretch px-4"
  }, [_c('v-text-field', {
    attrs: {
      "prepend-inner-icon": "mdi-map-marker",
      "outlined": "",
      "autofocus": "",
      "solo": "",
      "hide-details": "",
      "flat": _vm.showResults,
      "disabled": _vm.isLoading
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.getSearchResults.apply(null, arguments);
      },
      "focus": function focus($event) {
        _vm.isSearchFocus = true;
      }
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function fn() {
        return [_c('v-menu', {
          attrs: {
            "offset-y": "",
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "disabled": _vm.isLoading,
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-map-marker")])], 1)];
            }
          }])
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-list-item-group', {
          attrs: {
            "color": "primary"
          },
          model: {
            value: _vm.maxDistance,
            callback: function callback($$v) {
              _vm.maxDistance = $$v;
            },
            expression: "maxDistance"
          }
        }, _vm._l(_vm.distanceOptions, function (option, i) {
          return _c('v-list-item', {
            key: i,
            attrs: {
              "value": option
            },
            on: {
              "click": function click($event) {
                return _vm.selectRadius(option);
              }
            }
          }, [_c('v-list-item-title', [_vm._v(_vm._s(option) + " km")])], 1);
        }), 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "disabled": _vm.isLoading,
            "icon": ""
          },
          on: {
            "click": _vm.getSearchResults
          }
        }, [_c('v-icon', [_vm._v("mdi-magnify")])], 1), _vm.isLoading || _vm.showResults ? _c('v-btn', {
          attrs: {
            "loading": _vm.isLoading,
            "icon": ""
          },
          on: {
            "click": _vm.clearSearch
          }
        }, [_c('v-icon', [_vm._v("mdi-close-circle")])], 1) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.searchLocal,
      callback: function callback($$v) {
        _vm.searchLocal = $$v;
      },
      expression: "searchLocal"
    }
  }), _c('v-chip-group', {
    staticClass: "pt-2 hidden-lg-and-up",
    attrs: {
      "row": "",
      "show-arrows": ""
    },
    on: {
      "change": _vm.getSearchResults
    },
    model: {
      value: _vm.filterLocal,
      callback: function callback($$v) {
        _vm.filterLocal = $$v;
      },
      expression: "filterLocal"
    }
  }, _vm._l(_vm.serviceChips, function (service) {
    return _c('v-chip', {
      key: service.value,
      attrs: {
        "color": "primary",
        "disabled": _vm.isLoading,
        "value": service.value,
        "small": ""
      }
    }, [service.value === _vm.filterLocal ? _c('v-avatar', {
      attrs: {
        "left": ""
      }
    }, [_c('v-icon', [_vm._v("mdi-checkbox-marked-circle")])], 1) : _vm._e(), _vm._v(" " + _vm._s(service.text) + " ")], 1);
  }), 1)], 1), _vm.showResults && !_vm.isMobile || _vm.isSearchFocus && _vm.isMobile ? _c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("components.CompanyMap.results", {
    amount: _vm.companies.length
  })) + " ")]) : _vm._e(), _vm.showResults && !_vm.isMobile || _vm.isSearchFocus && _vm.isMobile ? _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.CompanyMap.radius", {
    searchResultRadius: _vm.searchResultRadius
  })) + " ")]) : _vm._e(), _vm.showResults && !_vm.isMobile || _vm.isSearchFocus && _vm.isMobile ? _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-list-item-group', {
    ref: "companyList",
    attrs: {
      "color": "primary"
    },
    model: {
      value: _vm.selectedCompany,
      callback: function callback($$v) {
        _vm.selectedCompany = $$v;
      },
      expression: "selectedCompany"
    }
  }, [_c('v-virtual-scroll', {
    ref: "virtualScroll",
    attrs: {
      "id": "virtualScroll",
      "items": _vm.companies,
      "bench": 5,
      "item-height": _vm.itemHeight,
      "height": _vm.height
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var item = _ref2.item,
            index = _ref2.index;
        return [!item.loading ? _c('v-list-item', {
          key: index,
          ref: 'company-' + index,
          attrs: {
            "three-line": "",
            "value": item
          },
          on: {
            "click": function click($event) {
              return _vm.selectListItem(item);
            }
          }
        }, [_c('v-list-item-content', [_c('v-list-item-title', {
          staticClass: "text-truncate"
        }, [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.onCompanyNameClick(item);
            }
          }
        }, [_vm._v(_vm._s(item.companyName))])]), _c('v-list-item-subtitle', {
          staticClass: "text-truncate"
        }, [_vm._v(" " + _vm._s(item.addressString) + " ")]), _c('v-list-item-subtitle', {
          staticClass: "text-truncate"
        }, [_vm._v(" " + _vm._s(item.contact.email) + " ")]), _c('v-list-item-subtitle', {
          staticClass: "text-truncate"
        }, [_vm._v(" " + _vm._s(item.contact.phone) + " ")]), _c('v-list-item-subtitle', {
          staticClass: "text-xs text-truncate"
        }, [item.isFleet ? _c('span', [_c('v-chip', {
          staticClass: "mr-1",
          attrs: {
            "x-small": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("objects.company.isFleet")) + " ")])], 1) : _vm._e(), item.services ? _c('span', _vm._l(item.services, function (service) {
          return _c('v-chip', {
            key: 'service-enum' + service,
            staticClass: "mr-1",
            attrs: {
              "x-small": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("enums.CompanyServiceEnum.".concat(service))) + " ")]);
        }), 1) : _vm._e()])], 1), _c('v-list-item-action', [_c('v-chip', {
          attrs: {
            "x-small": "",
            "label": "",
            "color": "primary"
          }
        }, [_vm._v(" " + _vm._s(item.formattedDistance) + " ")])], 1)], 1) : _vm._e()];
      }
    }], null, false, 4249187422)
  })], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1), !_vm.isMobile ? _c('l-control', {
    staticClass: "hidden-md-and-down",
    staticStyle: {
      "max-width": "450px"
    }
  }, [_c('v-chip-group', {
    attrs: {
      "row": "",
      "show-arrows": ""
    },
    on: {
      "change": _vm.getSearchResults
    },
    model: {
      value: _vm.filterLocal,
      callback: function callback($$v) {
        _vm.filterLocal = $$v;
      },
      expression: "filterLocal"
    }
  }, _vm._l(_vm.serviceChips, function (service) {
    return _c('v-chip', {
      key: service.value,
      attrs: {
        "color": "primary",
        "disabled": _vm.isLoading,
        "value": service.value
      }
    }, [service.value === _vm.filterLocal ? _c('v-avatar', {
      attrs: {
        "left": ""
      }
    }, [_c('v-icon', [_vm._v("mdi-checkbox-marked-circle")])], 1) : _vm._e(), _vm._v(" " + _vm._s(service.text) + " ")], 1);
  }), 1)], 1) : _vm._e(), _vm._l(_vm.companies, function (company, idx) {
    return _c('l-marker', {
      key: idx,
      attrs: {
        "lat-lng": company.latLng,
        "icon": _vm.icon(company),
        "z-index-offset": _vm.selectedCompany && _vm.selectedCompany.id === company.id ? 1000 : 0
      },
      on: {
        "click": function click($event) {
          return _vm.onMarkerClick(company);
        }
      }
    }, [_c('l-popup', [_c('v-card', {
      attrs: {
        "flat": "",
        "width": "300"
      }
    }, [_c('v-card-title', {
      staticClass: "text-subtitle-1"
    }, [_c('a', {
      on: {
        "click": function click($event) {
          return _vm.onCompanyNameClick(company);
        }
      }
    }, [_vm._v(_vm._s(company.companyName))])]), _c('v-card-text', [_c('v-simple-table', {
      attrs: {
        "dense": ""
      }
    }, [_c('tbody', [_c('tr', [_c('td', {
      staticClass: "px-0 text-left iconWidth"
    }, [_c('v-btn', {
      attrs: {
        "icon": "",
        "small": "",
        "href": "https://www.google.com/maps/search/?q=".concat(encodeURIComponent(company.addressString)),
        "target": "_blank"
      }
    }, [_c('tooltip', {
      attrs: {
        "text": "Google Maps"
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-map-marker")])], 1)], 1)], 1), _c('td', {
      staticClass: "px-0 text-truncate maxWidth"
    }, [_c('span', [_vm._v(_vm._s(company.address.street))]), _vm._v(" "), _c('br'), _c('span', [_vm._v(_vm._s(company.address.zip) + " " + _vm._s(company.address.city))])]), _c('td', {
      staticClass: "text-right px-0 iconWidth",
      staticStyle: {
        "height": "43px"
      }
    }, [_c('v-btn', {
      attrs: {
        "icon": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return function () {
            return _vm.copyToClipboard(company.addressString);
          }.apply(null, arguments);
        }
      }
    }, [_c('tooltip', {
      attrs: {
        "text": _vm.$t('components.CopyClipboardText.copy')
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-content-copy")])], 1)], 1)], 1)]), company.contact && company.contact.email ? _c('tr', [_c('td', {
      staticClass: "px-0 text-left iconWidth"
    }, [_c('v-btn', {
      attrs: {
        "icon": "",
        "small": "",
        "href": "mailto:".concat(company.contact.email)
      }
    }, [_c('tooltip', {
      attrs: {
        "text": "Email"
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-email")])], 1)], 1)], 1), _c('td', {
      staticClass: "px-0 text-truncate maxWidth"
    }, [_vm._v(" " + _vm._s(company.contact.email) + " ")]), _c('td', {
      staticClass: "text-right px-0 iconWidth"
    }, [_c('v-btn', {
      attrs: {
        "icon": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return function () {
            return _vm.copyToClipboard(company.contact.email);
          }.apply(null, arguments);
        }
      }
    }, [_c('tooltip', {
      attrs: {
        "text": _vm.$t('components.CopyClipboardText.copy')
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-content-copy")])], 1)], 1)], 1)]) : _vm._e(), company.contact && company.contact.phone ? _c('tr', [_c('td', {
      staticClass: "px-0 text-left iconWidth"
    }, [_c('v-btn', {
      attrs: {
        "icon": "",
        "small": "",
        "href": "tel:".concat(company.contact.phone)
      }
    }, [_c('tooltip', {
      attrs: {
        "text": "Call"
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-phone")])], 1)], 1)], 1), _c('td', {
      staticClass: "px-0 text-truncate maxWidth"
    }, [_vm._v(" " + _vm._s(company.contact.phone) + " ")]), _c('td', {
      staticClass: "text-right px-0 iconWidth"
    }, [_c('v-btn', {
      attrs: {
        "icon": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return function () {
            return _vm.copyToClipboard(company.contact.phone);
          }.apply(null, arguments);
        }
      }
    }, [_c('tooltip', {
      attrs: {
        "text": _vm.$t('components.CopyClipboardText.copy')
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-content-copy")])], 1)], 1)], 1)]) : _vm._e(), company.services && company.services.length > 0 ? _c('tr', [_c('td', {
      staticClass: "px-0 pr-2 text-left iconWidth"
    }, [_c('v-btn', {
      attrs: {
        "icon": "",
        "small": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-briefcase")])], 1)], 1), _c('td', {
      staticClass: "px-0 text-truncate maxWidth"
    }, _vm._l(company.services, function (service) {
      return _c('v-chip', {
        key: 'service-enum' + service,
        staticClass: "mr-1",
        attrs: {
          "x-small": ""
        }
      }, [_vm._v(" " + _vm._s(_vm.$t("enums.CompanyServiceEnum.".concat(service))) + " ")]);
    }), 1), _c('td', {
      staticClass: "text-right px-0 iconWidth"
    })]) : _vm._e()])])], 1)], 1)], 1)], 1);
  }), _vm.geoLatLng ? _c('l-marker', {
    key: "search",
    attrs: {
      "lat-lng": _vm.geoLatLng,
      "icon": _vm.searchLatLngIcon
    }
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }